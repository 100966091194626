// import React from 'react';
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import React, { useState, useEffect } from "react";
import {
  // ChevronRight,
  Menu,
  X,
  MessageCircle,
  Truck,
  Type,
  Search,
  List,
  Zap,
  TrendingUp,
  Brain,
  Rocket,
  PieChart,
} from "lucide-react";

const languages = {
  en: {
    nav: {
      products: "Products",
      about: "About",
      documentation: "Documentation",
      getStarted: "Get Started",
    },
    hero: {
      title: "Revolutionize Your E-commerce with AI",
      subtitle: "Become the #1 seller with cutting-edge AI technology",
      cta: "Get Started",
    },
    products: {
      title: "Our Products",
      items: [
        {
          icon: "MessageCircle",
          title: "Pre-Sales Virtual Assistant",
          description:
            "AI-powered question management and conversion optimization",
        },
        {
          icon: "Truck",
          title: "Post-Sales Virtual Assistant",
          description: "Efficient handling of orders and shipments",
        },
        {
          icon: "Type",
          title: "E-commerce Title Manager",
          description: "Optimized titles with country-specific SEO",
        },
        {
          icon: "Search",
          title: "Competition and Price Analyzer",
          description: "Stay ahead with intelligent market insights",
        },
        {
          icon: "List",
          title: "AI Catalog Management",
          description: "Streamline your product catalog with AI",
        },
      ],
    },
    approach: {
      title: "Boost Your Business",
      cards: [
        {
          icon: "Zap",
          title: "Instant 24/7 Response",
          description:
            "Night owls? No problem! Respond in seconds, anytime, anywhere. Never miss a sale because of sleeping hours.",
        },
        {
          icon: "MessageCircle",
          title: "Smart Chatbot for MercadoLibre",
          description:
            "Your 24/7 virtual assistant! Answer questions, offer recommendations, and close sales while you sleep. It's like having a digital clone of yourself.",
        },
        {
          icon: "TrendingUp",
          title: "Boost Your Sales Like a Pro",
          description:
            "Our AI is like having a team of expert sales representatives 24/7. Increase your conversions and let the AI do the hard work while you relax.",
        },
        {
          icon: "Brain",
          title: "AI That Learns and Improves",
          description:
            "Our AI is like a friend who gets to know you better every day. It learns from every interaction to provide more accurate answers and increase your sales.",
        },
        {
          icon: "Rocket",
          title: "Scale Your Business Effortlessly",
          description:
            "Handle hundreds of inquiries without breaking a sweat. Grow your business without needing to hire more staff. Less stress, more success.",
        },
        {
          icon: "PieChart",
          title: "Easy-to-Understand Analytics",
          description:
            "Clear and simple data about your sales and customers. Make smart decisions without needing to be a numbers genius.",
        },
      ],
    },
    testimonials: {
      title: "What Our Customers Say",
      cards: [
        {
          quote:
            "MercadoBoost has revolutionized my business on MercadoLibre. Now I can answer all my customers' questions in seconds.",
          author: "Maria L. - Clothing Seller",
        },
        {
          quote:
            "Since I started using MercadoBoost, my sales have increased by 30%. The AI responds better than I could.",
          author: "Carlos R. - Electronics Store",
        },
      ],
    },
    cta: {
      title: "Ready to Boost Your Sales?",
      description:
        "Start using MercadoBoost today and experience the power of AI in your MercadoLibre sales.",
      placeholder: "Enter your email",
      button: "Get Started",
    },
    footer: {
      copyright: "© 2025 MercadoBoost. All rights reserved.",
      terms: "Terms of Service",
      privacy: "Privacy Policy",
    },
  },
  es: {
    nav: {
      products: "Productos",
      about: "Nosotros",
      documentation: "Documentación",
      getStarted: "Comenzar",
    },
    hero: {
      title: "Revoluciona tu E-commerce con IA",
      subtitle:
        "Conviértete en el vendedor #1 con tecnología de IA de vanguardia",
      cta: "Comenzar",
    },
    products: {
      title: "Nuestros Productos",
      items: [
        {
          icon: "MessageCircle",
          title: "Asistente Virtual de Preventas",
          description:
            "Gestión de preguntas con IA y optimización de conversión",
        },
        {
          icon: "Truck",
          title: "Asistente Virtual de Post Ventas",
          description: "Manejo eficiente de órdenes y envíos",
        },
        {
          icon: "Type",
          title: "Gestor de Títulos para E-commerce",
          description: "Títulos optimizados con SEO específico por país",
        },
        {
          icon: "Search",
          title: "Gestor de Competencias y Analizador de Precios",
          description:
            "Mantente adelante con insights inteligentes del mercado",
        },
        {
          icon: "List",
          title: "Manejo de Catálogo con IA",
          description: "Optimiza tu catálogo de productos con IA",
        },
      ],
    },
    approach: {
      title: "Potencia tu Negocio",
      cards: [
        {
          icon: "Zap",
          title: "Respuestas Instantáneas 24/7",
          description:
            "¿Clientes nocturnos? ¡No hay problema! Responde en segundos a cualquier hora y desde cualquier lugar. Nunca más perderás una venta por dormir.",
        },
        {
          icon: "MessageCircle",
          title: "Chatbot Inteligente para MercadoLibre",
          description:
            "¡Tu asistente virtual 24/7! Responde preguntas, ofrece recomendaciones y cierra ventas mientras duermes. Es como tener un clon digital de ti mismo.",
        },
        {
          icon: "TrendingUp",
          title: "Impulsa tus Ventas como un Pro",
          description:
            "Nuestra IA es como tener un equipo de ventas experto 24/7. Aumenta tus conversiones y deja que la IA haga el trabajo duro mientras tú te relajas.",
        },
        {
          icon: "Brain",
          title: "IA que Aprende y Mejora",
          description:
            "Nuestra IA es como un amigo que cada día te conoce mejor. Aprende de cada interacción para dar respuestas más acertadas y aumentar tus ventas.",
        },
        {
          icon: "Rocket",
          title: "Escala tu Negocio sin Complicaciones",
          description:
            "Maneja cientos de consultas sin sudar. Crece tu negocio sin necesidad de contratar más personal. Menos estrés, más éxito.",
        },
        {
          icon: "PieChart",
          title: "Analítica Fácil de Entender",
          description:
            "Datos claros y sencillos sobre tus ventas y clientes. Toma decisiones inteligentes sin necesidad de ser un genio de los números.",
        },
      ],
    },
    testimonials: {
      title: "Lo que dicen nuestros clientes",
      cards: [
        {
          quote:
            "MercadoBoost ha revolucionado mi negocio en MercadoLibre. Ahora puedo responder a todas las preguntas de mis clientes en cuestión de segundos.",
          author: "María L. - Vendedora de ropa",
        },
        {
          quote:
            "Desde que empecé a usar MercadoBoost, mis ventas han aumentado un 30%. La IA responde mejor de lo que yo podría hacerlo.",
          author: "Carlos R. - Tienda de electrónica",
        },
      ],
    },
    cta: {
      title: "¿Listo para impulsar tus ventas?",
      description:
        "Comienza a usar MercadoBoost hoy y experimenta el poder de la IA en tus ventas de MercadoLibre.",
      placeholder: "Ingresa tu email",
      button: "Comenzar",
    },
    footer: {
      copyright: "© 2025 MercadoBoost. Todos los derechos reservados.",
      terms: "Términos de servicio",
      privacy: "Política de privacidad",
    },
  },
  pt: {
    nav: {
      products: "Produtos",
      about: "Sobre",
      documentation: "Documentação",
      getStarted: "Começar",
    },
    hero: {
      title: "Revolucione seu E-commerce com IA",
      subtitle: "Torne-se o vendedor nº 1 com tecnologia de IA de ponta",
      cta: "Começar",
    },
    products: {
      title: "Nossos Produtos",
      items: [
        {
          icon: "MessageCircle",
          title: "Assistente Virtual de Pré-vendas",
          description:
            "Gerenciamento de perguntas com IA e otimização de conversão",
        },
        {
          icon: "Truck",
          title: "Assistente Virtual de Pós-vendas",
          description: "Gerenciamento eficiente de pedidos e envios",
        },
        {
          icon: "Type",
          title: "Gerenciador de Títulos para E-commerce",
          description: "Títulos otimizados com SEO específico por país",
        },
        {
          icon: "Search",
          title: "Analisador de Concorrência e Preços",
          description:
            "Mantenha-se à frente com insights inteligentes do mercado",
        },
        {
          icon: "List",
          title: "Gerenciamento de Catálogo com IA",
          description: "Otimize seu catálogo de produtos com IA",
        },
      ],
    },
    approach: {
      title: "Potencialize seu Negócio",
      cards: [
        {
          icon: "Zap",
          title: "Respostas Instantâneas 24/7",
          description:
            "Clientes noturnos? Sem problemas! Responda em segundos, a qualquer hora e de qualquer lugar. Nunca mais perca uma venda por causa do horário de sono.",
        },
        {
          icon: "MessageCircle",
          title: "Chatbot Inteligente para MercadoLibre",
          description:
            "Seu assistente virtual 24/7! Responda perguntas, ofereça recomendações e finalize vendas enquanto você dorme. É como ter um clone digital de si mesmo.",
        },
        {
          icon: "TrendingUp",
          title: "Impulsione suas Vendas como um Profissional",
          description:
            "Nossa IA é como ter uma equipe de representantes de vendas experientes 24/7. Aumente suas conversões e deixe a IA fazer o trabalho pesado enquanto você relaxa.",
        },
        {
          icon: "Brain",
          title: "IA que Aprende e Melhora",
          description:
            "Nossa IA é como um amigo que te conhece melhor a cada dia. Ela aprende com cada interação para fornecer respostas mais precisas e aumentar suas vendas.",
        },
        {
          icon: "Rocket",
          title: "Expanda seu Negócio sem Dificuldades",
          description:
            "Gerencie centenas de consultas sem suar. Cresça seu negócio sem precisar contratar mais funcionários. Menos estresse, mais sucesso.",
        },
        {
          icon: "PieChart",
          title: "Análise Fácil de Entender",
          description:
            "Dados claros e simples sobre suas vendas e clientes. Tome decisões inteligentes sem precisar ser um gênio da matemática.",
        },
      ],
    },
    testimonials: {
      title: "O que nossos clientes dizem",
      cards: [
        {
          quote:
            "MercadoBoost revolucionou meu negócio no MercadoLibre. Agora posso responder a todas as perguntas dos meus clientes em segundos.",
          author: "Maria L. - Vendedora de roupas",
        },
        {
          quote:
            "Desde que comecei a usar o MercadoBoost, minhas vendas aumentaram 30%. A IA responde melhor do que eu poderia.",
          author: "Carlos R. - Loja de eletrônicos",
        },
      ],
    },
    cta: {
      title: "Pronto para Impulsionar suas Vendas?",
      description:
        "Comece a usar o MercadoBoost hoje e experimente o poder da IA em suas vendas no MercadoLibre.",
      placeholder: "Insira seu email",
      button: "Começar",
    },
    footer: {
      copyright: "© 2025 MercadoBoost. Todos os direitos reservados.",
      terms: "Termos de serviço",
      privacy: "Política de privacidade",
    },
  },
};

export default function Component() {
  const [lang, setLang] = useState("es");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const t = languages[lang as keyof typeof languages];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getIcon = (iconName: string) => {
    switch (iconName) {
      case "MessageCircle":
        return <MessageCircle className="w-6 h-6" />;
      case "Truck":
        return <Truck className="w-6 h-6" />;
      case "Type":
        return <Type className="w-6 h-6" />;
      case "Search":
        return <Search className="w-6 h-6" />;
      case "List":
        return <List className="w-6 h-6" />;
      case "Zap":
        return <Zap className="w-6 h-6" />;
      case "TrendingUp":
        return <TrendingUp className="w-6 h-6" />;
      case "Brain":
        return <Brain className="w-6 h-6" />;
      case "Rocket":
        return <Rocket className="w-6 h-6" />;
      case "PieChart":
        return <PieChart className="w-6 h-6" />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Gradient background */}
      <div className="fixed inset-0 bg-gradient-to-br from-gray-900 via-purple-900 to-indigo-900 opacity-50 z-0"></div>

      {/* Header */}
      <header className="relative z-10 bg-gray-900 bg-opacity-90 backdrop-blur-md">
        <nav className="container mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <img
                src="/MercadoBoostLogo.png"
                alt="MercadoBoost Logo"
                className="h-8 w-auto"
              />
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => setLang("en")}
                  className="focus:outline-none"
                  aria-label="English"
                >
                  🇺🇸
                </button>
                <button
                  onClick={() => setLang("es")}
                  className="focus:outline-none"
                  aria-label="Español"
                >
                  🇪🇸
                </button>
                <button
                  onClick={() => setLang("pt")}
                  className="focus:outline-none"
                  aria-label="Português"
                >
                  🇧🇷
                </button>
              </div>
            </div>

            <div className="hidden md:flex items-center space-x-8">
              <a
                href="#products"
                className="text-sm hover:text-purple-400 transition-colors"
              >
                {t.nav.products}
              </a>
              <a
                href="#about"
                className="text-sm hover:text-purple-400 transition-colors"
              >
                {t.nav.about}
              </a>
              <a
                href="#documentation"
                className="text-sm hover:text-purple-400 transition-colors"
              >
                {t.nav.documentation}
              </a>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScLl7mtR4n_A56JIO0F5BJi6KH5tCNMlddjukL65OPDjBq4kw/viewform"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-full text-sm transition-colors"
              >
                {t.nav.getStarted}
              </a>
            </div>

            <button
              className="md:hidden text-white focus:outline-none"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>

          {isMenuOpen && (
            <div className="mt-4 md:hidden">
              <a
                href="#products"
                className="block py-2 hover:text-purple-400 transition-colors"
              >
                {t.nav.products}
              </a>
              <a
                href="#about"
                className="block py-2 hover:text-purple-400 transition-colors"
              >
                {t.nav.about}
              </a>
              <a
                href="#documentation"
                className="block py-2 hover:text-purple-400 transition-colors"
              >
                {t.nav.documentation}
              </a>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScLl7mtR4n_A56JIO0F5BJi6KH5tCNMlddjukL65OPDjBq4kw/viewform"
                target="_blank"
                rel="noopener noreferrer"
                className="mt-4 w-full bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-full text-sm transition-colors"
              >
                {t.nav.getStarted}
              </a>
            </div>
          )}
        </nav>
      </header>

      {/* Main Content */}
      <main className="relative z-10">
        {/* Hero Section */}
        <section className="container mx-auto px-6 py-20 md:py-32 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 leading-tight">
            {lang === "en"
              ? "Revolutionize Your E-commerce with AI"
              : lang === "es"
              ? "Revoluciona tu E-commerce con IA"
              : "Revolucione seu E-commerce com IA"}
          </h1>
          <p className="text-xl md:text-2xl mb-10 text-gray-300 max-w-3xl mx-auto">
            {lang === "en"
              ? "Become the #1 seller with cutting-edge AI technology"
              : lang === "es"
              ? "Conviértete en el vendedor #1 con tecnología de IA de vanguardia"
              : "Torne-se o vendedor nº 1 com tecnologia de IA de ponta"}
          </p>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScLl7mtR4n_A56JIO0F5BJi6KH5tCNMlddjukL65OPDjBq4kw/viewform"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-purple-600 hover:bg-purple-700 text-white text-lg px-8 py-3 rounded-full transition-colors inline-block"
          >
            {t.hero.cta}
          </a>
        </section>

        {/* Products Section */}
        <section id="products" className="bg-gray-800 py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
              {t.products.title}
            </h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {t.products.items.map((product, index) => (
                <div
                  key={index}
                  className="bg-gray-700 rounded-lg p-6 transition-all duration-300 hover:shadow-lg hover:shadow-purple-500/20 hover:-translate-y-1"
                >
                  <div className="text-purple-400 mb-4">
                    {getIcon(product.icon)}
                  </div>
                  <h3 className="text-xl font-semibold mb-2">
                    {product.title}
                  </h3>
                  <p className="text-gray-300">{product.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Approach Section */}
        <section id="approach" className="py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
              {t.approach.title}
            </h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {t.approach.cards.map((card, index) => (
                <div
                  key={index}
                  className="bg-gray-800 rounded-lg p-6 transition-all duration-300 hover:shadow-lg hover:shadow-purple-500/20 hover:-translate-y-1"
                >
                  <div className="text-purple-400 mb-4">
                    {getIcon(card.icon)}
                  </div>
                  <h3 className="text-xl font-semibold mb-2">{card.title}</h3>
                  <p className="text-gray-300">{card.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Testimonials Section */}
        <section id="testimonials" className="bg-gray-800 py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
              {t.testimonials.title}
            </h2>
            <div className="grid md:grid-cols-2 gap-8">
              {t.testimonials.cards.map((testimonial, index) => (
                <div
                  key={index}
                  className="bg-gray-700 rounded-lg p-6 transition-all duration-300 hover:shadow-lg hover:shadow-purple-500/20"
                >
                  <p className="mb-4 italic text-gray-300">
                    "{testimonial.quote}"
                  </p>
                  <p className="font-semibold">{testimonial.author}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-20">
          <div className="container mx-auto px-6 text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              {t.cta.title}
            </h2>
            <p className="text-xl mb-10 text-gray-300 max-w-2xl mx-auto">
              {t.cta.description}
            </p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScLl7mtR4n_A56JIO0F5BJi6KH5tCNMlddjukL65OPDjBq4kw/viewform"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-purple-600 hover:bg-purple-700 text-white px-6 py-2 rounded-full transition-colors inline-block"
            >
              {t.cta.button}
            </a>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-gray-900 py-8">
        <div className="container mx-auto px-6 text-center">
          <p className="text-gray-500">{t.footer.copyright}</p>
          <nav className="mt-4">
            <button
              // href="#"
              className="text-gray-400 hover:text-purple-400 transition-colors mx-2"
            >
              {t.footer.terms}
            </button>
            <button
              // href="#"
              className="text-gray-400 hover:text-purple-400 transition-colors mx-2"
            >
              {t.footer.privacy}
            </button>
          </nav>
        </div>
      </footer>
    </div>
  );
}
